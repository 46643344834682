import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AuthProvider from './components/Auth/Authenticate';
import RouteVerifier from './components/RouteVerifier';
import Loading from './components/Loading';
import LocationDetails from './components/Forms/LocationDetails';
import LiftAvailability from './components/Forms/LiftAvailability';
import ItemDetailsForm from './components/Forms/ItemDetailsForm';
import About from './pages/About';
import YelahankaBangalore from './pages/YelahankaBangalore';
import Contact from './pages/Contact';
import OurNetwork from './pages/OurNetwork';
import Jaipur from './pages/Jaipur';
import Vaishali from './pages/Vaishali';
import Neemrana from './pages/Neemrana';
import Pali from './pages/Pali';
import Sikar from './pages/Sikar';
import Kolkata from './pages/Kolkata';
import Services from './pages/Services';
import Profile from './pages/profile';
import OrderPreview from './pages/OrderPreview';
import NotFound from './pages/NotFound';
import HowWeWork from './pages/HowWeWork';
// import EstimateMovingPrice from './components/Forms/EstimateMovingPrice';

function App() {
  return (
    <Router>
      <Switch>
        <AuthProvider>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/home" component={Home} />
            <Route path="/loading" component={Loading} />
            <Route path="/login" component={Login} />
            <Route path="/about-us" component={About} />
            <Route path="/contact-us" component={Contact} />
            <Route path="/mymover-packers-and-movers-network" component={OurNetwork} />
            <Route path="/packers-and-movers-in-jaipur" component={Jaipur} />
            <Route path="/packers-and-movers-in-pali" component={Pali} />
            <Route path="/packers-and-movers-in-neemrana" component={Neemrana} />
            <Route path="/packers-and-movers-in-sikar" component={Sikar} />
            <Route path="/packers-and-movers-in-vaishali" component={Vaishali} />
            <Route path="/packers-and-movers-in-kolkata" component={Kolkata} />
            <Route path="/packers-and-movers-in-yelahanka-bangalore" component={YelahankaBangalore} />
            <Route path="/services" component={Services} />
            <Route path="/calculate-packers-and-movers-charges" component={LocationDetails} />
            {/* <Route path="/calculate-packers-and-movers-charges" component={EstimateMovingPrice} /> */}
            <Route path="/lift-availability" component={LiftAvailability} />
            <Route path="/how-mymover-packers-and-movers-work" component={HowWeWork} />

            <RouteVerifier path="/profile" component={Profile} protectedRoute />
            <RouteVerifier path="/orders/:id" component={OrderPreview} protectedRoute />
            <RouteVerifier path="/dashboard" component={Dashboard} protectedRoute />
            <RouteVerifier path="/item-details" component={ItemDetailsForm} protectedRoute />

            <Route path="*" component={NotFound} />
          </Switch>
        </AuthProvider>
      </Switch>
    </Router>
  );
}

export default App;

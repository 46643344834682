import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import FancyButton from '../components/FancyButton';
import Loading from '../components/Loading';
import {
  BlackButton,
} from '../styles/CommonStyles';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function Neemrana(props) {
  const [network, setNetwork] = useState({});
  const [selectedCity, setCity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Packers and Movers in Neemrana, Rajasthan";

    // fetch data from firebase
    database().ref('/network').once('value', snap => {
      const data = snap.val();
      setNetwork(data);
      setCity(Object.keys(data)[0]);
      setLoading(false);
    })
  }, []);

  const DisplayBranchAddress = (e, city) => {
    e.preventDefault();
    if(city === "Sikar") { 
      props.history.push(`/packers-and-movers-in-sikar`);
      return;
    } else if(city === "Jaipur") { 
      props.history.push(`/packers-and-movers-in-jaipur`);
      return;
    } else if(city === "Vaishali") { 
      props.history.push(`/packers-and-movers-in-Vaishali`);
      return;
    } else if(city === "Pali") { 
      props.history.push(`/packers-and-movers-in-pali`);
      return;
    } else if(city === "Neemrana") { 
      props.history.push(`/packers-and-movers-in-neemrana`);
      return;
    } else if(city === "Kolkata") { 
      props.history.push(`/packers-and-movers-in-kolkata`);
      return;
    } else if (city === "Bangalore") {
      props.history.push(`/packers-and-movers-in-yelahanka-bangalore`);
      return;
    } else {
      setCity(city);
      return;
    }
  }
  if(loading) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '1rem',
          color: theme.primary
        }}>Packers and Movers in Neemrana, Rajasthan</h1>
        <h3><a href="https://mymover.in">MyMover.in</a> | Calculate movers and packers Charges in Neemrana</h3>
        <p>
        Looking for best movers and packers service in neemrana to relocate your household, car and bike Shifting? 
        MyMover packers and movers provide <a href = "/calculate-packers-and-movers-charges">calculating moving cost
        </a> instantly and <a href="/calculate-packers-and-movers-charges">book now</a> your shifting for household 
        shifting, vehicle shifting while being in your budget. MyMover packers and movers offers fast and reliable 
        moving service with superior high quality Packing, skilled labor that is trained to handle all the belongings, 
        commercial or personal, with utmost care in Neemrana. We are one of the best reliable movers and packers in 
        neemrana. We have Moved 10000+ houses in Neemrana and rajasthan. MyMover movers and packers neemrana offers 
        value added household relocation services at Reasonable Price. Our main aim is to make shifting and relocation
        easy and hassle free for customers.
        </p>

        <p>
        Many professional movers and packers in Neemrana Rajasthan, but its difficult to identify 
        that which is right one for you. You will get perfect House Shifting service at MyMover 
        packers and movers Neemrana at reasonable price. House Shifting to a new location in Neemrana 
        isn't a easy Work, and it become more curcial to think twice when you are shifting your 
        valuable and Precious goods. If you know what relocation services you require then you can 
        save money, by choosing MyMover packers and movers Neemrana for relocation Intracity or across india.
        </p>
        
        <StyledButton>
          <Link to="/calculate-packers-and-movers-charges">
            <BlackButton style={{textTransform: 'uppercase'}}>Calculate Moving Cost</BlackButton>
          </Link>
        </StyledButton>
        <br/>
        <h3>Neemrana Office address</h3>
        <b>My Movers and Packers</b>
        <p style={{marginBottom: '2rem'}}>  
        Shop No. 41, opposite Hotel, Place, 
        Neemrana, Rajasthan 301705<br />
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9166668369">+91-9166668369</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymover.in">MyMover.in</a>
        </p>
        <h2 style={{textAlign: 'center'}}>
          Our Branches
        </h2>
        {
          selectedCity && network[selectedCity] && (
            <div>
              <h4>{selectedCity}</h4>
              <div style={{paddingBottom: '10px'}}>{network[selectedCity]['subtitle']}</div>
              <div style={{paddingBottom: '10px'}}>{network[selectedCity]['address']}</div>
              <div>
                Contact: &nbsp;
                <a href={`tel:${network[selectedCity]['phone']}`}>{network[selectedCity]['phone']}</a>
              </div>
            </div>
          )
        }
        <CityContainer>
          {
            Object.keys(network).map((city, index) => (
              <FancyButton
                text={city}
                key={index}
                onClick={(e) => DisplayBranchAddress(e, city)}
                active={selectedCity === city}
              />
            ))
          }
        </CityContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Neemrana;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;
  p {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const CityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
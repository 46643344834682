import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyAPsDLzYJm6AujUNDWuA7f6dn6_obc4Ec8",
  authDomain: "mover-1.firebaseapp.com",
  databaseURL: "https://mover-1.firebaseio.com",
  projectId: "mover-1",
  storageBucket: "mover-1.appspot.com",
  messagingSenderId: "300368020488",
  appId: "1:300368020488:web:1159b1a2a46a09edc7bdbd",
  measurementId: "G-1Q00Q96S9V"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import FancyButton from '../components/FancyButton';
import Loading from '../components/Loading';
import {BlackButton} from '../styles/CommonStyles';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function YelahankaBangalore(props) {
  const [network, setNetwork] = useState({});
  const [selectedCity, setCity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Packers and Movers in Bangalore,Karnataka";

    // fetch data from firebase
    database().ref('/network').once('value', snap => {
      const data = snap.val();
      setNetwork(data);
      setCity(Object.keys(data)[0]);
      setLoading(false);
    })

  }, []);
  const DisplayBranchAddress = (e, city) => {
    e.preventDefault();
    if(city === "Sikar") { 
      props.history.push(`/packers-and-movers-in-sikar`);
      return;
    } else if(city === "Jaipur") { 
      props.history.push(`/packers-and-movers-in-jaipur`);
      return;
    } else if(city === "Vaishali") { 
      props.history.push(`/packers-and-movers-in-Vaishali`);
      return;
    } else if(city === "Pali") { 
      props.history.push(`/packers-and-movers-in-pali`);
      return;
    } else if(city === "Neemrana") { 
      props.history.push(`/packers-and-movers-in-neemrana`);
      return;
    } else if(city === "Kolkata") { 
      props.history.push(`/packers-and-movers-in-kolkata`);
      return;
    } else if (city === "Bangalore") {
      props.history.push(`/packers-and-movers-in-yelahanka-bangalore`);
      return;
    } else {
      setCity(city);
      return;
    }
  }
  if(loading) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '1rem',
          color: theme.primary
        }}>Packers and Movers in Yelahanka, Bangalore, Karnataka</h1>
        <h3><a href="https://mymover.in">MyMover.in</a> | Calculate movers and packers charges in Yelahanka Bangalore</h3>

        <p>
        Looking for best movers and packers service in Yelahanka, Bangalore to relocate your household, car and bike Shifting? 
        MyMover movers and packers Yelahanka, Bangalore is the best packers and movers company in Yelahanka, Bangalore. MyMover packers and 
        movers offers calculating moving cost instantly and book your shifting for household shifting, vehicle shifting 
        while being in your budget. MyMover packers and movers offers fast and reliable moving service with superior 
        high quality Packing, skilled labor that is trained to handle all the belongings, commercial or personal, 
        with utmost care in Yelahanka, Bangalore.</p>
        
        <p>
        To relocate your valuable assets from one place to other safely, you need a professional company which has  good 
        expertise and handle relocation with extreame care . In big cities like Bangalore, there are lots of options available 
        for good packers and movers. But, which one is the best suitable firm for your relocation. The company should have 
        good resources and well trained staff to properly handle your belongings. To fulfill your asset moving with the  
        highest perfection, MyMover packers and movers are offering its best services to you. MyMover is serving as leading packers 
        and movers firm in Bangalore with highly reliable, convenient and satisfactory service. MyMover Packers and Movers  
        offers service for move to any region of india as well as foreign moves too. Once your relocation booking with us, 
        you need not to worry about anything. MyMover will handle everything from packaging, loading to relocate, unloading 
        with our best resources and highly well trained staff. we have done many projects earlier and with those projects, 
        we have become expertise in relocation field. Our staff is expert in packing different types of 
        materials with high quality material and move them by using the most suitable transportation mediums. Our staff can handle 
        any amount of work easily and with excellence. MyMover packers and movers is able to move your household belongings, 
        vehicles, organizational assets totally hassle free, convenient and satisfactory manner. MyMover movers and packers is fully 
        dedicated to excellent and customer satisfaction.
        </p>

        <p>
        MyMover packers and movers is one of the best reliable movers and packers in Yelahanka, Bangalore. We have moved 10000+ houses 
        in Bangalore. MyMover movers and packers Yelahanka, Bangalore offers value added household relocation 
        services at Reasonable Price. Our main aim is to make shifting and relocation easy and hassle free for customers.
        </p>
        
        <StyledButton>
          <Link to="/calculate-packers-and-movers-charges">
            <BlackButton style={{textTransform: 'uppercase'}}>Calculate Moving Cost</BlackButton>
          </Link>
        </StyledButton>

        <br/>
        <h3>Yelahanka, Bangalore Office address</h3>
        <p style={{marginBottom: '3rem'}}>  
          <b>My Movers and Packers</b> <br/>
          209, Kwality Raag Apartment, Anantapuram Gate, <br />
          BSF Campus, Yelahanka, Bengaluru, Karnataka 560064  <br/>
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9799357100">+91-9799357100</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymover.in">MyMover.in</a>
        </p>

        <h2 style={{textAlign: 'center'}}>
          Our Branches
        </h2>
        {
          selectedCity && network[selectedCity] && (
            <div>
              <h4>{selectedCity}</h4>
              <div style={{paddingBottom: '10px'}}>{network[selectedCity]['subtitle']}</div>
              <div style={{paddingBottom: '10px'}}>{network[selectedCity]['address']}</div>
              <div>
                Contact: &nbsp;
                <a href={`tel:${network[selectedCity]['phone']}`}>{network[selectedCity]['phone']}</a>
              </div>
            </div>
          )
        }
        <CityContainer>
          {
            Object.keys(network).map((city, index) => (
              <FancyButton
                text={city}
                key={index}
                onClick={(e) => DisplayBranchAddress(e, city)}
                active={selectedCity === city}
              />
            ))
          }
        </CityContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default YelahankaBangalore;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;
  p {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const CityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { database } from 'firebase';
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../styles/theme';
import FancyButton from '../components/FancyButton';
import Loading from '../components/Loading';
import {
  BlackButton,
} from '../styles/CommonStyles';
import MobileFloatingActionButtons from '../components/MobileFloatingActionButtons';

function Kolkata(props) {
  const [network, setNetwork] = useState({});
  const [selectedCity, setCity] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "MyMover in Kolkata,West Bengal";

    // fetch data from firebase
    database().ref('/network').once('value', snap => {
      const data = snap.val();
      setNetwork(data);
      setCity(Object.keys(data)[0]);
      setLoading(false);
    })

  }, []);

  const DisplayBranchAddress = (e, city) => {
    e.preventDefault();
    if(city === "Sikar") { 
      props.history.push(`/packers-and-movers-in-sikar`);
      return;
    } else if(city === "Jaipur") { 
      props.history.push(`/packers-and-movers-in-jaipur`);
      return;
    } else if(city === "Vaishali") { 
      props.history.push(`/packers-and-movers-in-Vaishali`);
      return;
    } else if(city === "Pali") { 
      props.history.push(`/packers-and-movers-in-pali`);
      return;
    } else if(city === "Neemrana") { 
      props.history.push(`/packers-and-movers-in-neemrana`);
      return;
    } else if(city === "Kolkata") { 
      props.history.push(`/packers-and-movers-in-kolkata`);
      return;
    } else if (city === "Bangalore") {
      props.history.push(`/packers-and-movers-in-yelahanka-bangalore`);
      return;
    } else {
      setCity(city);
      return;
    }
  }

  if(loading) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <MobileFloatingActionButtons />
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '1rem',
          color: theme.primary
        }}>Packers & Movers in Kolkata,West Bengal</h1>
        <h3><a href="https://mymover.in">MyMover.in</a> | Calculate movers and packers Charges in Kolkata</h3>
        <p>
        Looking for best movers and packers service in Kolkata to relocate your household, car and bike Shifting? 
        MyMover packers and movers provide <a href = "/calculate-packers-and-movers-charges">calculating moving cost
        </a> instantly and <a href="/calculate-packers-and-movers-charges">book now</a> your shifting for household 
        shifting, vehicle shifting while being in your budget. MyMover packers and movers offers fast and reliable 
        moving service with superior high quality Packing, skilled labor that is trained to handle all the belongings, 
        commercial or personal, with utmost care in Kolkata. We are one of the best reliable movers and packers in 
        Kolkata. We have Moved 10000+ houses in Kolkata and West Bengal. MyMover movers and packers Kolkata offers 
        value added household relocation services at Reasonable Price. Our main aim is to make shifting and relocation
        easy and hassle free for customers.
        </p>

        <p>
        Kolkata (formerly known as Calcutta) is the capital of the Indian state of West Bengal and is located in 
        eastern India on the east bank of the River Bhagirathi-Hooghly (Ganga). The city was a colonial city developed 
        by the East India Company and then by the British Empire. Kolkata was the capital of the British Indian empire 
        until 1911, when the capital was relocated to Delhi. Kolkata grew rapidly in the 19th century to become the 
        second most important city of the British Indian Empire. This was accompanied by the development of a culture 
        that fused Indian philosophies with Victorian tradition. 
        Kolkata is also noted for its revolutionary history, ranging from the Indian to the leftist Naxalite and 
        trade-union movements. Labelled the "Cultural Capital of India", "The City of Processions", "The City of Palaces", 
        and the "City of Joy", Kolkata has also been home to prominent statesman and yogis. Problems related to rapid 
        urbanization started to plague Kolkata from the 1653s and the city remains an example of the urbanization challenges 
        of the developing nations.
        </p>

        <p>
        Many professional movers and packers in Kolkata West Bengal, but its difficult to identify 
        that which is right one for you. You will get perfect House Shifting service at MyMover 
        packers and movers Kolkata at reasonable price. House Shifting to a new location in Kolkata 
        isn't a easy Work, and it become more curcial to think twice when you are shifting your 
        valuable and Precious goods. If you know what relocation services you require then you can 
        save money, by choosing MyMover packers and movers Kolkata for relocation Intracity or across india.
        </p>
        
        <StyledButton>
          <Link to="/calculate-packers-and-movers-charges">
            <BlackButton style={{textTransform: 'uppercase'}}>Calculate Moving Cost</BlackButton>
          </Link>
        </StyledButton>
        <br/>
        <h3>Kolkata Office address</h3>
        <h3>MyMover Packers and Movers</h3>
        <p style={{marginBottom: '2rem'}}>  
          Sankha Villa, Ward no 11, swarupnagr,<br/>
          Dankuni, hoogly, Kolkata, West Bengal- 712311 <br/>
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-8880007708">+91-8880007708</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymover.in">MyMover.in</a>
        </p>

        <h2 style={{textAlign: 'center'}}>
          Our Branches
        </h2>
        {
          selectedCity && network[selectedCity] && (
            <div>
              <h4>{selectedCity}</h4>
              <div style={{paddingBottom: '10px'}}>{network[selectedCity]['subtitle']}</div>
              <div style={{paddingBottom: '10px'}}>{network[selectedCity]['address']}</div>
              <div>
                Contact: &nbsp;
                <a href={`tel:${network[selectedCity]['phone']}`}>{network[selectedCity]['phone']}</a>
              </div>
            </div>
          )
        }
        <CityContainer>
          {
            Object.keys(network).map((city, index) => (
              <FancyButton
                text={city}
                key={index}
                onClick={(e) => DisplayBranchAddress(e, city)}
                active={selectedCity === city}
              />
            ))
          }
        </CityContainer>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Kolkata;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;
  p {
    text-align: justify;
    text-justify: inter-word;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;

const CityContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const StyledButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
import rates from './data/rates.json'

const generate20DigitOrderId = () => {
  /**
   * @description [generate20DigitOrderId]
   * @returns 20 digit random reference id (e.g. #883-2883833-4437848)
   */
  const arr = [];
  let x = 17; // 17 random numbers are required
  while(x--) {
    arr.push(Math.floor(Math.random() * 10));
  }
  const refNum = arr.slice(0,3).join("") + '-' + arr.slice(3,10).join("") + '-' + arr.slice(10, 17).join("");
  return refNum;
}



const getVolumeCategory = (volume) => {
  if(volume <= 5) return "cat5";
  else if(volume <= 10) return "cat10";
  else if(volume <= 15) return "cat15";
  else if(volume <= 20) return "cat20";
  else if(volume <= 25) return "cat25";
  else if(volume <= 30) return "cat30";
  else if(volume <= 35) return "cat35";
  else if(volume <= 100) return "cat100";
  else if(volume <= 160) return "cat160";
  else if(volume <= 300) return "cat300";
  else if(volume <= 400) return "cat400";
  else if(volume <= 500) return "cat500";
  else if(volume <= 600) return "cat600";
  else if(volume <= 800) return "cat800";
  else if(volume <= 1000) return "cat1000";
  else if(volume <= 1200) return "cat1200";
  return "catPeak";
}

/**
 * Return distance category in respective to volume category
 * @param {*} distance 
 * @param {*} volCategory 
 * @returns 
 */
const getDistanceCategory = (distance, volume) => {
  if (volume <= 35) {
    if (distance <= 10) return "10";
    else if (distance <= 450) return "450";
    else return "2300";
  } else if (volume <= 600){
    if (distance<=10) return "10";
    else if (distance <= 30) return "30";
    else if(distance <= 75) return "75";
    else if(distance <= 230) return "230";
    else return "2300";
  } else {
    if (distance <= 30) return "30";
    else if(distance <= 75) return "75";
    else if(distance <= 230) return "230";
    else if(distance <= 1000) return "1000";
    else if(distance <= 1500) return "1500";
    else return "2300";
  }
}

/**
 * Calculate transport, packaging, loading/unloading price based on volume, distance
 * Note: cat500 transport price = cat800 price -3000
 * @param {*} type 
 * @param {*} distance 
 * @param {*} volume 
 * @param {*} distanceCategory 
 * @param {*} volumeCategory 
 * @returns 
 */
const getCharges = (type, distance, volume, distanceCategory, volumeCategory = "cat800") => {
  // for volume between 0 to 25 cubeft and distance > 10
  if(type === "Transport" && volume <= 25 && distance > 10 && distance <= 230){
    let totalTransportCharge;
    const typeOfCharge = `${type} Charges`;
    const fixedCharge = parseInt(rates["charges"][volumeCategory]["10"][typeOfCharge], 10);
    totalTransportCharge =  fixedCharge + (distance - 10)*15;
    return totalTransportCharge;
  }
    // for volume between 26 to 35 cubeft and distance > 10
  else if(type === "Transport" && volume > 25 && volume <= 35 && distance > 10 && distance <= 230){
    let totalTransportCharge;
    const typeOfCharge = `${type} Charges`;
    const fixedCharge = parseInt(rates["charges"][volumeCategory]["10"][typeOfCharge], 10);
    totalTransportCharge =  fixedCharge + (distance - 10)*40;
    return totalTransportCharge;
  }
  // for volume between 36 to 100 cubeft and distance > 30
  else if (type === "Transport" && volume > 35 && volume <= 100 && distance > 30) {
      //calculate transport charge based on volume category
      let totalTransportCharge;
      if (distance <= 75) {
        const typeOfCharge = `${type} Charges`;
        // fix transport charge(from rates) + formula 
        const fixedCharge = parseInt(rates["charges"][volumeCategory]["75"][typeOfCharge], 10);
        totalTransportCharge =  fixedCharge + (distance - 30)*40;
      } else if (distance <= 230) {
        const typeOfCharge = `${type} Charges`;
        const fixedCharge = parseInt(rates["charges"][volumeCategory]["230"][typeOfCharge], 10);
        totalTransportCharge =  fixedCharge + (distance - 75)*40;
      } else if(distance > 230) {
        totalTransportCharge = 180*volume;
      } 
      //check for upper limit based on distance
      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }
   // for volume between 101 to 160 cubeft and distance > 10
    else if(type === "Transport" &&  volumeCategory === "cat160" && distance > 10){
      let totalTransportCharge;
      if (distance <= 230){
        const typeOfCharge = `${type} Charges`;
        const fixedCharge = parseInt(rates["charges"][volumeCategory]["10"][typeOfCharge], 10);
        totalTransportCharge =  fixedCharge + (distance - 10)*35;
      } else {
        totalTransportCharge = 125*volume;
      }

      //check for upper limit based on distance
      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }
    // for volume between 161 to 300 cubeft and distance > 10
    else if(type === "Transport" &&  volumeCategory === "cat300" && distance > 10){
      let totalTransportCharge;
      const typeOfCharge = `${type} Charges`;
      // fixed charge from 30 km
      const fixedCharge = parseInt(rates["charges"][volumeCategory]["30"][typeOfCharge], 10);
      totalTransportCharge =  fixedCharge + (distance - 10)*40;

      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }
    // for volume between 301 to 400 cubeft and distance > 10
    else if(type === "Transport" &&  volumeCategory === "cat400" && distance > 10){
      let totalTransportCharge;
      const typeOfCharge = `${type} Charges`;
      // fixed charge from 30 km
      const fixedCharge = parseInt(rates["charges"][volumeCategory]["30"][typeOfCharge], 10);
      totalTransportCharge =  fixedCharge + (distance - 10)*45;

      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }
    // for volume between 401 to 500 cubeft and distance > 10
    else if(type === "Transport" &&  volumeCategory === "cat500" && distance > 10){
      let totalTransportCharge;
      const typeOfCharge = `${type} Charges`;
      // fixed charge from 30 km
      const fixedCharge = parseInt(rates["charges"][volumeCategory]["30"][typeOfCharge], 10);
      totalTransportCharge =  fixedCharge + (distance - 10)*50;

      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }

    // for volume between 501 to 600 cubeft and distance > 10
    else if(type === "Transport" &&  volumeCategory === "cat600" && distance > 10){
      let totalTransportCharge;
      const typeOfCharge = `${type} Charges`;
      // fixed charge from 30 km
      const fixedCharge = parseInt(rates["charges"][volumeCategory]["30"][typeOfCharge], 10);
      totalTransportCharge =  fixedCharge + (distance - 10)*55;

      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }

    // for volume between 601 to 800 cubeft and distance > 10
    else if(type === "Transport" &&  volumeCategory === "cat800" && distance > 10){
      let totalTransportCharge;
      const typeOfCharge = `${type} Charges`;
      const typeOfRate = `${type} Rate`;
      const fixedCharge = parseInt(rates["charges"][volumeCategory]["30"][typeOfCharge], 10);
      if(distance <= 450){
        // fixed charge from 30 km
        totalTransportCharge =  fixedCharge + (distance - 10)*60;
      } else{
        const transportRate = parseInt(rates["charges"][volumeCategory][distanceCategory][typeOfRate], 10);
        totalTransportCharge =  fixedCharge + (distance - 10) * transportRate;
      }
      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else if (distance <= 1000) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["1000"], 10));
      } else if (distance <= 1500) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["1500"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }
    // for volume between 801 to 1000 cubeft and distance > 10
    else if(type === "Transport" &&  volumeCategory === "cat1000" && distance > 10){
      let totalTransportCharge;
      const typeOfCharge = `${type} Charges`;
      const typeOfRate = `${type} Rate`;
      const fixedCharge = parseInt(rates["charges"][volumeCategory]["30"][typeOfCharge], 10);
      if(distance <= 450){
        // fixed charge from 30 km
        totalTransportCharge =  fixedCharge + (distance - 10)*65;
      } else{
        const transportRate = parseInt(rates["charges"][volumeCategory][distanceCategory][typeOfRate], 10);
        totalTransportCharge =  fixedCharge + (distance - 10) * transportRate;
      }
      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else if (distance <= 1000) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["1000"], 10));
      } else if (distance <= 1500) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["1500"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }
    // for volume between 1001 to 1200 cubeft and distance > 10
    else if(type === "Transport" &&  volumeCategory === "cat1200" && distance > 10){
      let totalTransportCharge;
      const typeOfCharge = `${type} Charges`;
      const typeOfRate = `${type} Rate`;
      const fixedCharge = parseInt(rates["charges"][volumeCategory]["30"][typeOfCharge], 10);
      if(distance <= 450){
        // fixed charge from 30 km
        totalTransportCharge =  fixedCharge + (distance - 10)*65;
      } else{
        const transportRate = parseInt(rates["charges"][volumeCategory][distanceCategory][typeOfRate], 10);
        totalTransportCharge =  fixedCharge + (distance - 10) * transportRate;
      }
      if (distance <= 450) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["450"], 10));
      } else if (distance <= 1000) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["1000"], 10));
      } else if (distance <= 1500) {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["1500"], 10));
      } else {
        return Math.min(totalTransportCharge, parseInt(rates["transportChargeLimit"][volumeCategory]["2300"], 10));
      }
    }

  //Packaging cases
  else if (type === "Packaging" &&  volume <= 35) {
    const typeOfCharge = `${type} Charges`;
    return parseInt(rates["charges"][volumeCategory]["2300"][typeOfCharge]);
  }
  else if (type === "Packaging" &&  volume > 35) {
    const typeOfCharge = `${type} Charges`;
    if( distance <= 75) {
      return parseInt(rates["charges"][volumeCategory]["75"][typeOfCharge]);
    } 
    else if(distance <= 230){
      return parseInt(rates["charges"][volumeCategory]["230"][typeOfCharge]);
    } else{
      return Math.min(20*volume, parseInt(rates["packagingChargeLimit"][volumeCategory], 10));
    }
  }
  //Loading/unloading cases
  else if ((type === "Loading" || type === "Unloading") &&  volume <= 35) {
    const typeOfCharge = `${type} Charges`;
    return parseInt(rates["charges"][volumeCategory]["2300"][typeOfCharge]);
  }
  else if ((type === "Loading" || type === "Unloading") &&  volume > 35) {
    const typeOfCharge = `${type} Charges`;
    if(distance <= 75) {
      return parseInt(rates["charges"][volumeCategory]["75"][typeOfCharge]);
    }
    else if(distance <= 230){
      return parseInt(rates["charges"][volumeCategory]["230"][typeOfCharge]);
    } else{
      return Math.min(13*volume, parseInt(rates["LoadingChargeLimit"][volumeCategory], 10));
    }
  }
  else {
    const typeOfCharge = `${type} Charges`;
    return parseInt(rates["charges"][volumeCategory][distanceCategory][typeOfCharge], 10);
  }
}

const getSubListFromLabel2 = (label1, label2, subListData) => {
  if(subListData) {
    const label1Data = subListData.find(d => d.title === label1);
    return { ...label1Data };
  }
}

const getSubList2FromLabel3 = (label1, label2, label3, subListData) => {
  if(subListData) {
    const label1Data = subListData.find(d => d.title === label1);
    const label2Data = label1Data.data.find(d => d.title === label2);
    return { ...label2Data };
  }
}

// const mid = 'UcPspp53745225178083'; // test
const mid = 'FobYbl84689856876454'; // prod


export {
  generate20DigitOrderId,
  getVolumeCategory,
  getDistanceCategory,
  getCharges,
  mid,
  getSubListFromLabel2,
  getSubList2FromLabel3
};
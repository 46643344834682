import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Axios from 'axios';
import swal from '@sweetalert/with-react';
import { database } from 'firebase';

import Header from '../Header';
import firebase from '../firebaseConfig';

import {
  StyledButton,
  ButtonContainer,
  FadeInUpAnimation,
  StyledInput,
  FormGroup,
  StyledLabel,
} from '../../styles/CommonStyles';

import {
  generate20DigitOrderId,
  getVolumeCategory,
  getDistanceCategory,
  getCharges,
  mid
} from '../../utils/commonFunctions';

import data from '../../utils/data/itemDetails.json';
import idWiseDetails from '../../utils/data/objIdToName.json';
import { auth } from 'firebase';
import Loading from '../Loading';
import Accordion from '../Accordion';

function ItemDetailsForm(props) {
  const [selectedItems, setSelectedItems] = useState({});
  const [saving, setSaving] = useState(false);
  const [userPhone, setPhone] = useState(() => auth().currentUser.phoneNumber || "");
  const [userEmail, setEmail] = useState(() => auth().currentUser.email || "");
  const [userName, setName]  = useState(() => auth().currentUser.displayName || "");
  const [profileLoading, setProfLoading] = useState(true);
  const [calculatingPrice, setCalcPrice] = useState(false);

  const mainRef = useRef();

  useEffect(() => {
    getProfile()
      .finally(() => {
        setProfLoading(false);
        // mainRef.current.focus();
      });
  }, []);

  const getProfile = async () => {
    const uid = auth().currentUser ? auth().currentUser.uid : null;
    if(uid) {
      const snap = await database().ref(`/profiles/${uid}`).once('value');
      const profile = snap.val();
      if(!profile || !profile.name || !profile.email || !profile.phone) {
        props.history.push('/profile', {redirectUrl: '/item-details'});
      } else {
        const { name, email, phone } = profile;
        setName(name);
        setEmail(email);
        setPhone(phone);
      }
    } else {
      return {};
    }
  }

  const submitForm = async e => {
    e.preventDefault();

    const name = userName;
    const email = userEmail;
    const phone = userPhone;

    if(!name) swal('please provide your name');
    else if(!email) swal('please provide your email');
    else {

      // locationDetails form validation
      const dateFromLs = localStorage.getItem('movingDate');
      if(dateFromLs && !moment(dateFromLs).isSameOrAfter(moment())) {
        alert('past date selected in location details form. Click OK to update.');
        props.history.push('/calculate-packers-and-movers-charges');
        return;
      }

      const locationDetails = {
        "from-location": JSON.parse(localStorage.getItem('from-location')),
        "to-location": JSON.parse(localStorage.getItem('to-location')),
        // "shifting-bhk": localStorage.getItem('shifting-bhk'),
        "movingDate": dateFromLs
      }

      const locationKeys = Object.keys(locationDetails);
      let missingLLocationKey = false;

      for(let i=0; i<locationKeys.length; i++) {
        if(!locationDetails[locationKeys[i]]) {
          missingLLocationKey = true;
          break;
        }
      }

      if(missingLLocationKey) {
        alert('missing field in location details form, click OK to fill.');
        props.history.push('/calculate-packers-and-movers-charges');
        return;
      }

      // lift-availability form validation
      const liftAvailability = {
        currentLocationFloor: localStorage.getItem('currentLocationFloor'),
        currentLocationLift: localStorage.getItem('currentLocationLift'),
        movingLocationFloor: localStorage.getItem('movingLocationFloor'),
        movingLocationLift: localStorage.getItem('movingLocationLift')
      }

      const liftKeys = Object.keys(liftAvailability);
      let missingLiftAvailabilityKey = false;

      for(let i=0; i<liftKeys.length; i++) {
        if(!liftAvailability[liftKeys[i]]) {
          missingLiftAvailabilityKey = true;
          break;
        }
      }

      if(missingLiftAvailabilityKey) {
        alert('missing field in lift availability form, click OK to fill.');
        props.history.push('/lift-availability');
        return;
      }

      let totalDistanceInMeters = localStorage.getItem('distanceInMeters');
      console.log('Total Distance(Km): ' + totalDistanceInMeters * 0.001);
      if (!totalDistanceInMeters) {
        // check if from and to locations are availble in local storage
        const fromLocation = JSON.parse(localStorage.getItem('from-location'));
        const toLocation = JSON.parse(localStorage.getItem('to-location'));
        if(fromLocation && toLocation) {
          setCalcPrice(true);
          const distanceURL = `https://us-central1-mover-1.cloudfunctions.net/calcDistance?origin_id=${fromLocation.place_id}&destination_id=${toLocation.place_id}`;
          try {
            const { data : { data } } = await Axios.get(distanceURL);
            totalDistanceInMeters = data.rows[0].elements[0].distance.value;
            localStorage.setItem('distanceInMeters', totalDistanceInMeters);
          } catch(err) {
            alert(err.message);
          } finally {
            setCalcPrice(false);
          }
        } else {
          // fetch location details using place ids 
          alert('Wrong location details. Please fill location details again.');
          props.history.push('/calculate-packers-and-movers-charges');
          return;
        }
      }

      let totalVolume = 0;
      Object.keys(selectedItems).forEach(key => {
        if(key !== null || key !== undefined) {
          totalVolume += (idWiseDetails[key].size * selectedItems[key]);
        }
      });
      // calcualte fields to be saved into database
      if(totalVolume > 0) {
        // calcualte transport rates

        const distanceInKM = totalDistanceInMeters * 0.001;
        const volumeCategory = getVolumeCategory(totalVolume);
        const distanceCategory = getDistanceCategory(distanceInKM, totalVolume);

        const transportCharges = getCharges('Transport', distanceInKM, totalVolume, distanceCategory, volumeCategory);
        const packagingCharges = getCharges('Packaging', distanceInKM, totalVolume, distanceCategory, volumeCategory);
        const loadingCharges = getCharges('Loading', distanceInKM, totalVolume, distanceCategory, volumeCategory);
        const unloadingCharges = getCharges('Unloading', distanceInKM, totalVolume, distanceCategory, volumeCategory);

        let totalPrice = Math.max(transportCharges + packagingCharges + loadingCharges + unloadingCharges, 2000);

        const payableAmount = parseFloat(totalPrice).toFixed(2) * 0.2;  // 20% of total amount :xD

        // console.log('totalVolume', totalVolume);
        // console.log('transportCharges', transportCharges);
        // console.log('packagingCharges', packagingCharges);
        // console.log('loadingCharges', loadingCharges);
        // console.log('unloadingCharges', unloadingCharges);
        // console.log(totalPrice);

        const updatedSelectedItems = Object.keys(selectedItems).filter(itemID => selectedItems[itemID] > 0).map(itemID => {
          const qty = selectedItems[itemID];
          return {
            itemID: parseInt(itemID, 10),
            name: idWiseDetails[itemID] && idWiseDetails[itemID].name,
            qty
          }
        });

        const payload = {
          phone,
          email,
          name,
          transportCharges,
          packagingCharges,
          loadingCharges,
          unloadingCharges,
          selectedItems: updatedSelectedItems,
          totalDistanceInMeters: parseInt(totalDistanceInMeters, 10),
          totalVolume,
          totalPrice: totalPrice.toFixed(),
          payableAmount: payableAmount.toFixed(2),
          liftAvailability: {...liftAvailability},
          locationDetails: {...locationDetails}
        };
          
        //Notify mymover about itemList of order
        const api = 'https://us-central1-mover-1.cloudfunctions.net';
        const url = `${api}/sendEmailWithItemList?phone=${phone}&email=${email}&name=${name}&transportCharges=${transportCharges}
        &packagingCharges=${packagingCharges}&loadingCharges=${loadingCharges}&unloadingCharges=${unloadingCharges}
        &selectedItems=${JSON.stringify(payload.selectedItems)}&totalDistanceInMeters=${payload.totalDistanceInMeters}&totalVolume=${totalVolume}
        &totalPrice=${payload.totalPrice}&payableAmount=${payload.payableAmount}&liftAvailability=${JSON.stringify(payload.liftAvailability)}
        &locationDetails=${JSON.stringify(payload.locationDetails)}`;
        Axios.get(url)
          .then(data => {
            console.log('mymover notified with item list!!', data);
          })
          .catch(err => {
            console.log('err while notifying mymover...', err);
          });

        swal({
          content: (
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <h1>Pricing</h1>
              <table>
                <tbody>
                  <tr>
                    <td style={{height: '0.75rem'}}></td>
                    <td></td>
                  </tr>
                  <tr style={{textAlign: 'right'}}>
                    <td style={{ paddingRight: '1rem' }}>Transport Charge</td>
                    <td style={{textAlign: 'left'}}><b>₹ {transportCharges.toFixed(2)}</b></td>
                  </tr>
                  <tr style={{textAlign: 'right'}}>
                    <td style={{ paddingRight: '1rem' }}>Packaging Charge</td>
                    <td style={{textAlign: 'left'}}><b>₹ {packagingCharges.toFixed(2)}</b></td>
                  </tr>
                  <tr style={{textAlign: 'right'}}>
                    <td style={{ paddingRight: '1rem' }}>Loading Charge</td>
                    <td style={{textAlign: 'left'}}><b>₹ {loadingCharges.toFixed(2)}</b></td>
                  </tr>
                  <tr style={{textAlign: 'right'}}>
                    <td style={{ paddingRight: '1rem' }}>Unloading Charge</td>
                    <td style={{textAlign: 'left'}}><b>₹ {unloadingCharges.toFixed(2)}</b></td>
                  </tr>
                  <tr style={{textAlign: 'right'}}>
                    <td style={{ paddingRight: '1rem' }}>Sub Total</td>
                    <td style={{textAlign: 'left'}}><b>₹ {totalPrice.toFixed(2)}</b></td>
                  </tr>
                  <tr style={{textAlign: 'right'}}>
                    <td style={{ paddingRight: '1rem' }}>Booking Amount</td>
                    <td style={{textAlign: 'left'}}><b>₹ {parseFloat(payableAmount).toFixed(2)}</b></td>
                  </tr>
                </tbody>
              </table> 
              <p style={{fontSize: '12px', textAlign: 'left', marginBottom: 0}}>*Booking Amount is 20% of Sub Total*</p>
              <p style={{fontSize: '12px', marginTop: 0}}>*GST Exclusive*</p>
              {/* <p style={{fontSize: '18px', textAlign: 'center', marginBottom: 0}}>Our executive will connect with you shortly with estimates based on your list of items.</p>
              <p style={{fontSize: '12px', marginTop: 0}}>*Auto moving cost estimate work is in progress. *</p> */}
            </div>
          ),
          buttons: {
             "Edit": true,
            // placeOrder: {
            //   text: "Place Order",
            //   value: "placeOrder",
            // },
            "Okay": {
              text: "Okay",
              value: "Okay",
            },
          },
        })
        .then(async (value) => {
          if(value === "placeOrder") {
            setSaving(true);

            const db = firebase.database();
            const uid = firebase.auth().currentUser.uid;
            const orderId = generate20DigitOrderId();
            const timeStamp = Date.now();

            payload[uid] = uid;
            payload[orderId] = orderId;
            payload[timeStamp] = timeStamp;
            console.log("payload after order id: ", payload);

            // show user message : Please wait! and don't press back or refresh button
            document.body.innerHTML += `
              <form id="dynForm" action="https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=${mid}&orderId=${orderId}" method="post">
                <p style="margin-left: 1rem">Don't press back or refresh button!</p>  
                <input type="hidden" name="q" value="a" />
                <table border="1" style="opacity: 0">
                  <tbody>
                    <input type="hidden" name="mid" value=${mid}>
                    <input type="hidden" name="orderId" value=${orderId}>
                    <input type="hidden" id="paytmToken" name="txnToken" value="">
                  </tbody>
                </table>
              </form>`;

            // start paytm payment, follow the steps below:
            // 1. get transaction token
            // 2. Begin Standard checkout
            const url = `https://us-central1-mover-1.cloudfunctions.net/paytmToken?uid=${uid}&orderId=${orderId}&amount=${payableAmount}`;
            Axios.get(encodeURI(url))
            .then(async ({ data }) => {
              // save order details
              await db.ref(`/users/${uid}/orders/${orderId}`).set({...payload});

              setSaving(false);
              // console.log('data => ', data);

              const { status, token } = data || {};
              if(status === "success") {
                // submit the form
                document.getElementById('paytmToken').value = token;
                document.getElementById("dynForm").submit();
              } else {
                swal(`Error 501: Could not process payment at this time`);
              }
            })
            .catch(err => {
              setSaving(false);
              console.log('err in sending email', err);
            });
          } else if(value === "Okay"){
            props.history.push('/home');
          }
        });

      } else {
        swal('no items selected, please select items from dropdowns');
      }
    }
  }

  const updateData = ({ type, id, id1, id2 }) => {
    console.log(selectedItems);
    const newObj = {...selectedItems};

    if(type === "decrementThenIncrement") {
      if(id1) {
        newObj[id1] = newObj[id1] - 1;
      }
      id = id2;
      type = "increment";
    }

    if(selectedItems[id]) {
      newObj[id] = type === "increment" ? newObj[id] + 1 : Math.max(newObj[id] - 1, 0);
    } else {
      newObj[id] = type === "increment" ? 1 : 0;
    }

    setSelectedItems({ ...newObj });
  }

  const changeitemcount = (id, qty) => {
    console.log('selectedItems', selectedItems);
    setSelectedItems({ ...selectedItems, [id]: qty });
  }

  const handleNameChange = e => setName(e.target.value || 0);
  const handleEmailChange = e => setEmail(e.target.value || 0);

  if(profileLoading) {
    return <Loading />
  }

  if(saving) return <div style={{margin: '1rem', marginBottom: 0}}>Please Wait...</div>

  return (
    <React.Fragment>
      <Header {...props} />
      <Container>
        <Form>
          <h2 style={{marginTop: 0, marginBottom: '1rem'}}>Profile</h2>
          <FormGroup>
            <StyledLabel>Name *</StyledLabel>
            <StyledInput
              placeholder={"enter your name"}
              value={userName}
              onChange={handleNameChange}
              style={{width: 'auto'}}
              ref={mainRef}
            />
          </FormGroup>
          <FormGroup>
            <StyledLabel>Email *</StyledLabel>
            <StyledInput
              placeholder={"enter your email *"}
              value={userEmail}
              onChange={handleEmailChange}
              style={{width: 'auto'}}
            />
          </FormGroup>

          <h2 style={{marginTop: '2rem'}}>Select Items</h2>
          <FormGroup>
            {
              Object.keys(data).map(key => (
                <Accordion
                  heading={key}
                  data={data[key]}
                  selectedItems={selectedItems}
                  updateData={updateData}
                  changeitemcount={changeitemcount}
                  key={key}
                />
              ))
            }
          </FormGroup>

          <ButtonContainer style={{margin: 0}}>
            <StyledButton
              onClick={e => {
                e.preventDefault();
                !saving && submitForm(e);
              }}
              disabled={calculatingPrice || saving}
              loading={calculatingPrice || saving}
            >
              {
                saving
                  ? "Please wait..."
                  : calculatingPrice ? "calculating..." : "Calculate"
              }
            </StyledButton>
          </ButtonContainer>
        </Form>
      </Container>
    </React.Fragment>
  );
}

export default ItemDetailsForm;

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding-top: 10vh;
  margin-bottom: 20vh;
  position: relative;

  @media only screen and (max-width: 800px) { padding-top: 4vh; }
`;

const Form = styled.form`
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  padding: 2rem;
  border-radius: 8px;
  animation: ${FadeInUpAnimation} 0.45s;
  font-family: Montserrat, sans-serif;
  width: 95vw;
  max-width: 350px;

  @media only screen and (max-width: 800px) {
    box-shadow: none;
    padding: 8px;
    border: none;
    margin-top: 2rem;
  }
`;
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Contact(props) {
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  return (
    <React.Fragment>
      <Header {...props} />
      <Container>
        <h1 style={{
          textAlign: 'center',
          marginTop: 0,
          paddingTop: '4rem',
          paddingBottom: '2rem'
        }}>Contact Us</h1>
        <h3>My Movers and Packers</h3>
        <b>Jaipur Office address</b><br/>
        <p style={{marginBottom: '3rem'}}>
        <b>My Movers and Packers</b> <br/>
        B149, GANDHI PATH W, GIRNAR COLONY, NITYANAND NAGAR, <br />
        VAISHALI NAGAR, JAIPUR, Rajasthan - 302021 <br/>
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9166668368">+91-9166668368</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymover.in">MyMover.in</a>
        </p>

        <b>Pali, Rajasthan Office address</b>
        <p style={{marginBottom: '3rem'}}>
        <b>My Movers and Packers</b> <br/>  
        SHOP NO. 5, METRO PLAZA, AMBEDKAR CIRCLE,<br/>
        PALI, RAJASTHAN - 306401  <br/>
        <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9166668378">+91-9166668378</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymover.in">MyMover.in</a>
        </p>
        

        <b>Neemrana, Rajasthan Office address</b>
        <p style={{marginBottom: '3rem'}}>
        <b>My Movers and Packers</b> <br/>  
        SHOP NO. 41,<br />
        OPPOSITE HOTEL NEEMRANA PLACE NEEMRANA, RAJASTHAN - 301705<br />
        <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9166668369">+91-9166668369</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymover.in">MyMover.in</a>
        </p>
        

        <b>Sikar, Rajasthan Office address</b>
        <p style={{marginBottom: '3rem'}}>
        <b>My Movers and Packers</b> <br/> 
        SHOP NO. 117, SUPER AUTOMOBILE MARKET, <br/>
        JAIPUR- JHUNJHUNU BYPASS ROAD, SIKAR, RAJASTHAN - 332001<br/>
        <b>Contact</b><br/>
        Mobile No.: <a href="tel:+91-9799359921">+91-9799359921</a> <br />
        Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
        Website: <a href="https://mymover.in">MyMover.in</a>
        </p>


        <b>Yelahanka, Bangalore Office address</b>
        <p style={{marginBottom: '3rem'}}>  
          <b>My Movers and Packers</b> <br/>
          #209, Kwality Raag Apartment, Anantapuram Gate,<br />
          Yelahanka, Bengaluru, Karnataka – 560064  <br/>
          <b>Contact</b><br/>
          Mobile No.: <a href="tel:+91-9799357100">+91-9799357100</a> <br />
          Email ID: <a href="mailto:info@mymover.in">info@mymover.in</a> <br />
          Website: <a href="https://mymover.in">MyMover.in</a>
        </p>

        

        <iframe
          //src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d113743.909949391!2d75.7037509!3d27.0144511!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd91d37c31b954de6!2sMyMover!5e0!3m2!1sen!2sin!4v1588267603420!5m2!1sen!2sin"
          width="100%"
          height="450"
          frameBorder="0"
          style={{border: 0}}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="Location of My Mover on Google Maps"
        ></iframe>
      </Container>
      <Footer />
    </React.Fragment>
  )
}

export default Contact;

const Container = styled.div`
  width: 90vw;
  max-width: 650px;
  margin: auto;
  margin-bottom: 4rem;
  font-family: Montserrat, serif;

  p {
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 28px;
    font-size: 15px;
  }

  @media only screen and (max-width: 800) {
    padding: 1rem;
  }
`;
